<template>
  <header-t></header-t>
  <div class=" nPage taskShowPage">
    <div class=" BcBl1">
      <div class="nW breadcrumb">
        <!-- <el-page-header content="任务详情" @back="goBack" /> -->
        <el-breadcrumb separator-icon="Files" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }"
            ><svg-icon icon="user"
          /></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">任务大厅</el-breadcrumb-item>
          <!-- <el-breadcrumb-item><a href="/">任务详情</a></el-breadcrumb-item> -->
          <el-breadcrumb-item>发布任务</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="nW indexTaskHall Mt24">
      <div class="pageTitle b-b-1e Pb12">
        <strong class="tit">创建任务</strong>
      </div>
      <div v-if="entZt">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="110px"
          class="demo-ruleForm"
          :size="formSize"
        >
          <div class="taskTitle">
            <el-form-item label="任务标题" prop="taskName">
              <el-input
                v-model="ruleForm.taskName"
                placeholder="请输入任务标题"
              />
            </el-form-item>
          </div>
          <div class="addTaskBox">
            <div class="pleft">
              <el-form-item label="悬赏积分" prop="integral">
                <el-input
                  v-model="ruleForm.integral"
                  @blur="xuanshangjifen"
                  type="number"
                  ><template #append>积分</template></el-input
                >
                <span style="color:#666;"
                  >最高可悬赏
                  <b style="color:#d00">{{ us.integral }}</b> 积分</span
                >
              </el-form-item>
              <el-form-item label="行业" prop="taskType">
                <el-select v-model="ruleForm.taskType" placeholder="请选择">
                  <el-option
                    v-for="(iam, iax) in hangye"
                    :key="iax"
                    :label="iam.fieldLabel"
                    :value="iam.fieldValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="时间要求" prop="hours">
                <el-input
                  v-model="ruleForm.hours"
                  type="number"
                  placeholder="请输入工时"
                  @blur="zhuanshuzi('hours')"
                >
                  <template #append>小时</template>
                </el-input>
              </el-form-item>
              <el-form-item label="开始时间" required>
                <el-form-item prop="">
                  <el-date-picker
                    v-model="tdat.instart"
                    type="date"
                    @change="dateIsstartFun"
                    placeholder="开始时间"
                    style="width: 100%"
                  />
                </el-form-item>
              </el-form-item>
              <el-form-item label="结束时间" required>
                <el-form-item prop="">
                  <el-date-picker
                    v-model="tdat.isend"
                    @change="dateIsendFun"
                    type="date"
                    placeholder="结束时间"
                    style="width: 100%"
                  />
                </el-form-item>
              </el-form-item>
              <el-form-item label="承接人员" prop="">
                <el-radio-group v-model="ruleForm.peopleType">
                  <el-radio :label="1">不限</el-radio>
                  <el-radio :label="2">企业</el-radio>
                  <el-radio :label="3">个人</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="雇佣人数" prop="planPeopleNumber">
                <el-input
                  v-model="ruleForm.planPeopleNumber"
                  type="number"
                  @blur="zhuanshuzi('planPeopleNumber')"
                >
                  <template #append>人</template>
                </el-input>
              </el-form-item>
              <el-form-item label="承接保证金" prop="margin">
                <el-input
                  v-model="ruleForm.margin"
                  minlength="1"
                  type="number"
                  :min="0"
                  @blur="zhuanshuzi('margin')"
                >
                  <template #append>积分</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="pcont">
              <div class="">
                <el-form-item label="任务内容" prop="etxt">
                  <el-input
                    style="display:none"
                    v-model="ruleForm.etxt"
                    placeholder="请输入任务内容"
                    type="textarea"
                    rows="12"
                  />
                  <div v-show="txs1" id="editor-box"></div>
                </el-form-item>
                <el-form-item label="验收标准" prop="etxt2">
                  <el-input
                    style="display:none"
                    v-model="ruleForm.etxt2"
                    placeholder="请输入验收标准"
                    rows="12"
                    type="textarea"
                  />
                  <div v-show="txs2" id="editor-box2"></div>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="entDiv">
            <el-button
              type="primary"
              style="min-width: 250px;"
              @click="submitForm(ruleFormRef)"
            >
              提交任务
            </el-button>
          </div>
        </el-form>
      </div>
      <div v-else>
        <el-result
          icon="success"
          title="提交成功！"
          sub-title="任务已经发布，请在任务大厅查看~"
        >
          <template #extra>
            <el-button type="primary" @click="goPage('/index')"
              >回任务大厅</el-button
            >
          </template>
        </el-result>
      </div>
    </div>
  </div>
  <footer-s></footer-s>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { postAddTask } from '@/api/user'
import { dictionary } from '@/api/sys'
import { sign, getItem } from '@/utils/storage'
import { ElMessage } from 'element-plus'
import HeaderT from '@/components/Public/Header'
import FooterS from '@/components/Public/Footer'
import tools from '@/utils/tools.js'
import EK from 'wangeditor'
import { useRouter } from 'vue-router'
// import TagsView from '@/components/TagsView/index.vue'
const router = useRouter()
const entZt = ref(true) // 提交状态
const txs1 = ref(false)
const txs2 = ref(false)
const us = getItem('userinfo')
const hangye = ref([])
const hangyeFun = async () => {
  hangye.value = await dictionary(sign({ type: 'industry' }))
}
hangyeFun() // 行业

const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = ref({
  taskName: '', // 任务名
  integral: null, // 积分
  taskType: null, // 任务类型
  planPeopleNumber: 1, // 计划人数
  hours: 60, // 小时
  tg: 'ok',
  planStartDate: null, // 计划开始时间
  planEndDate: null, // 计划结束时间
  peopleType: 1, // 人员类型
  margin: 10, // 保证金
  etxt: '', // 任务内容 taskContent
  etxt2: '' // 验收标准 acceptanceCriteria
})
const tdat = ref({
  instart: '',
  isend: ''
})

// 输入内容转数字
const zhuanshuzi = n => {
  ruleForm.value[n] = parseInt(ruleForm.value[n])
}

const rules = ref({
  taskName: [
    { required: true, message: '不能为空', trigger: 'blur' },
    { min: 3, max: 60, message: '3-60个字符', trigger: 'blur' }
  ],
  integral: [
    { required: true, message: '积分不能为空', trigger: 'blur' },
    {
      required: true,
      pattern: /^[1-9]\d*$/,
      message: '积分不能为0',
      trigger: 'blur'
    }
  ],
  taskType: [
    {
      required: true,
      message: '请选择类型',
      trigger: 'change'
    }
  ],
  hours: [{ required: true, message: '时间不能为空', trigger: 'blur' }],
  planStartDate: [
    {
      type: 'date',
      required: true,
      message: '选择开始时间',
      trigger: 'change'
    }
  ],
  planEndDate: [
    {
      type: 'date',
      required: true,
      message: '选择结束时间',
      trigger: 'change'
    }
  ],
  margin: [
    { required: true, message: '保证金不能为空', trigger: 'blur' },
    {
      required: true,
      pattern: /^[0-9]\d*$/,
      message: '保证金最小为0,不能有小数点',
      trigger: 'blur'
    }
  ],
  planPeopleNumber: [
    { required: true, message: '雇佣人数不能为空', trigger: 'blur' },
    {
      required: true,
      pattern: /^[1-9]\d*$/,
      message: '雇佣人数需大于0',
      trigger: 'blur'
    }
  ],
  etxt: [{ required: true, message: '请输入内容', trigger: 'blur' }],
  etxt2: [{ required: true, message: '请输入验收标准内容', trigger: 'blur' }]
})

// Editor实例
let editor
let editor2
// 处理离开页面切换语言导致 dom 无法被获取
let el
let el2
onMounted(() => {
  el = document.querySelector('#editor-box')
  el2 = document.querySelector('#editor-box2')
  initEditor()
})
const initEditor = () => {
  editor = new EK(el)
  editor.config.zIndex = 1
  // 菜单栏提示
  editor.config.showMenuTooltips = true
  editor.config.menuTooltipPosition = 'down'
  editor.create()
  // 国际化相关处理
  // editor.config.lang = store.getters.language === 'zh' ? 'zh-CN' : 'en'
  // editor.i18next = i18next

  editor2 = new EK(el2)
  editor2.config.zIndex = 1
  // 菜单栏提示
  editor2.config.showMenuTooltips = true
  editor2.config.menuTooltipPosition = 'down'
  editor2.create()

  txs1.value = true
  txs2.value = true
  console.log('ok')
}

const submitForm = async formEl => {
  ruleForm.value.etxt = editor.txt.html()
  ruleForm.value.etxt2 = editor2.txt.html()
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (
        ruleForm.value.planEndDate === null ||
        ruleForm.value.planEndDate === null
      ) {
        ElMessage.error('请选择开始和结束时间')
      } else {
        tijiao(ruleForm.value)
      }
    } else {
      console.log('提交失败', fields, tijiao())
    }
  })
}
const tijiao = async data => {
  console.log('提交==', data)
  var revv = await postAddTask(data)
  if (revv === 'ok') {
    entZt.value = false
    // console.log('返回值OK-')
  }
}

const dateIsstartFun = date => {
  ruleForm.value.planStartDate = tools.datetime.dateFormat(date, 2)
}
const dateIsendFun = date => {
  ruleForm.value.planEndDate = tools.datetime.dateFormat(date, 2)
}

// 积分不能超用户总积分
const xuanshangjifen = () => {
  if (us.integral < ruleForm.value.integral) {
    ElMessage.error(
      '您当前积分为' + us.integral + ', 悬赏积分不能超过' + us.integral
    )
    ruleForm.value.integral = us.integral
  }
}

const goPage = ss => {
  router.push(ss)
}
</script>
<style lang="scss" scoped>
.taskTitle {
  margin-top: 48px;
}
.addTaskBox {
  display: flex;
  .pleft {
    flex: 1;
    margin-right: 48px;
  }
  .pcont {
    flex: 2;
  }
}
.entDiv {
  text-align: center;
}
.addTaskPtMar {
  margin-top: 2rem;
}
@media (max-width: 479px) {
  .taskTitle {
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .addTaskBox {
    display: block;
    .pleft {
      display: block;
      margin-right: 48px;
    }
    .pcont {
      flex: none;
    }
  }
}
</style>
